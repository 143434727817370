/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'gatsby';
import BlogExcerpts from './BlogExcerpts';
import Sidebar from './Sidebar';

const BlogList = ({ featured, posts, title, prevLink, nextLink }) => {
    return (
    <>
        {featured && !prevLink && (
        <div className="hero">
            {featured.large && featured.large.localFile && (
            <div className="hero__section hero__section--1" style={{backgroundImage: `url(${featured.large.localFile.childImageSharp.fluid.src})`}}>
                <Link className="blog-excerpt__linkcover" to={featured.fields.link} />
            </div>
            )}
            <div className="hero__section hero__section--2">
                <div className="hero__content">
                    <div className="hero__details">
                        <ul className="hero__tags tags">
                            <li className="tag">
                                <span className="tag__link">
                                    <span className="tag__text">{featured.fields.category}</span>
                                </span>
                            </li>
                        </ul>
                        <header className="hero__header">
                            <h2 className="hero__title">
                                <Link to={featured.fields.link} dangerouslySetInnerHTML={{__html: featured.title }} />
                            </h2>
                            <ul className="meta meta--reversed">
                                <li className="meta__item">
                                    {featured.date}
                                </li>
                            </ul>
                        </header>
                        <p className="hero__text" dangerouslySetInnerHTML={{__html: featured.fields.summary }} />
                    </div>
                </div>
                <ul className="hero__actions">
                    <li className="hero__action">
                        <Link to={featured.fields.link} className="hero__action__link">Read On</Link>
                    </li>
                </ul>
            </div>
        </div>
        )}   
        <div className="block block--xl-on-md">
            <div className="wrapper">
                {title && (
                    <header className="section-header">
                        <h3 className="section-header__title">{title}</h3>
                        <div className="section-header__break" />
                    </header>                
                )}
                <div className="page-cols">
                    <div className="page-col">
                        <BlogExcerpts posts={posts.slice(1) || []} />
                        <div className="pagination">
                            {prevLink && (
                            <Link to={prevLink} className="pagination__item prev">← Newer posts</Link>
                            )}
                            {nextLink && (
                            <Link to={nextLink} className="pagination__item next">Older posts →</Link>
                            )}
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </div>
        </div>
    </>
    );
};

export default BlogList;