/* eslint-disable react/jsx-indent */
import React from 'react';

const Sidebar = ({ title, children }) => {
    return (
		<div className="sidebar">
		  <div className="sidebar-block">
			{title && (
		    <header className="sidebar-header">
		      <h3 className="sidebar-header__title">
				{title}
		      </h3>
		    </header>
			)}
			{children}
		  </div>
		</div>
		
    );
};

export default Sidebar;
