/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React from 'react';
import { graphql } from 'gatsby';
import BlogList from '../components/BlogList';
import Layout from '../Page';
import SEO from '../components/SEO';

const BlogHolder = ({
	pageContext: {
		previousPagePath, nextPagePath },
		data: { featured: { nodes: feature }, allPosts: { nodes: posts }}
	}) => {
    return (
		<Layout>
			<SEO title="The Knackered Truth" description="A blog by Lissa Carlino" />
			<BlogList
				featured={feature[0]}
				posts={posts}
				prevLink={previousPagePath}
				nextLink={nextPagePath}
			/>
		</Layout>
    );
};

export default BlogHolder;

export const pageQuery = graphql`
	query AllBlogPosts($limit: Int!, $skip: Int!) {
		featured:allWordpressPost(sort: { fields: date, order: DESC }, limit: 1) {
			nodes {
				...PostFields
			}
		}
		allPosts:allWordpressPost(
			limit: $limit,
			skip: $skip,
			sort: { fields: date, order: DESC }
		) {
			nodes {
				...PostFields
			}		  
		}
	}
`;