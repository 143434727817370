/* eslint-disable react/jsx-indent */
import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const BlogExcerpts = ({ posts }) => {
    return (
		<div className="blog-excerpts">
		{posts.map(post => {
			return (
				<div className="blog-excerpt" key={post.id}>
					<div className="blog-excerpt__cover">
						<Link className="blog-excerpt__linkcover" to={post.fields.link} />
					{post.thumb && post.thumb.localFile && (
						<Img fluid={post.thumb.localFile.childImageSharp.fluid} className="blog-excerpt__img" />
					)}
					<ul className="blog-excerpt__tags tags">
						<li className="tag">
						<span className="tag__link">
							<span className="tag__text">{post.fields.category}</span>
						</span>
						</li>
					</ul>
					</div>
					<div className="blog-excerpt__details">
						<header className="blog-header">
							<h3 className="blog-header__title">
								<Link to={post.fields.link} dangerouslySetInnerHTML={{__html: post.title }} />
							</h3>
							<ul className="meta">
							<li className="meta__item">
								{post.date}
							</li>
							</ul>
						</header>
						<footer className="blog-excerpt__footer">
							<Link to={post.fields.link} className="blog-excerpt__read-on">Read on →</Link>
						</footer>
					</div>
				</div>
			)			
		})}
		</div>		
    );
};

export default BlogExcerpts;
